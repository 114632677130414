/* html{
  scroll-behavior: smooth;
} */

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  /* overflow-y: auto; */
}

@font-face {
  font-family: Open Sans;
  src: url("./Assets/fonts/OpenSans-Light.ttf") format("opentype");
  font-weight: 300;
}

@font-face {
  font-family: Open Sans;
  src: url("./Assets/fonts/OpenSans-Regular.ttf") format("opentype");
  font-weight: 400;
}

@font-face {
  font-family: Sofia Pro;
  src: url("./Assets/fonts/SofiaProLightAz.otf") format("opentype");
  font-weight: 300;
}

@font-face {
  font-family: Sofia Pro;
  src: url("./Assets/fonts/SofiaProRegularAz.otf") format("opentype");
  font-weight: 400;
}

@font-face {
  font-family: Sofia Pro;
  src: url("./Assets/fonts/SofiaProMediumAz.otf") format("opentype");
  font-weight: 500;
}

@font-face {
  font-family: Publico;
  src: url("./Assets/fonts/PublicoHeadlineWebBoldRegular.ttf")
    format("truetype");
  font-weight: bold;
}

@font-face {
  font-family: Poppins-light;
  src: url("./Assets/fonts/PoppinsExtraLight.ttf") format("truetype");
}

@font-face {
  font-family: Poppins;
  src: url("./Assets/fonts/PoppinsLight.ttf") format("truetype");
}

/* @font-face {
  font-family: Playfair Display; 
  src: url("./Assets/fonts/PlayfairDisplaySemiBold.ttf") format("truetype");
  font-weight: bold;
} */

@font-face {
  font-family: Playfair Display;
  src: url("./Assets/fonts/PlayfairDisplayBold.ttf") format("truetype");
  font-weight: bold;
}

.hand-cursor {
  cursor: pointer;
}

.success-message {
  color: green;
  font-family: "poppins";
}

.error-message {
  color: red;
  font-family: "poppins";
}

.three-dots {
  text-overflow: ellipsis;
  /* white-space: nowrap; */
  overflow: hidden;
  max-width: calc(100vw - 780px);
}

/* ============== */

html,
body,
#root {
  height: 100%;
  min-width: 100% !important;
  background-color: #f3f3f2;
}

.font-playfair-display {
  font-family: "Playfair Display";
}

.font-sofia-pro {
  font-family: "Sofia Pro";
  font-weight: 300;
}

.font-sofia-medium {
  font-family: "Sofia Pro";
  font-weight: 500;
}

.font-poppins {
  font-family: "Poppins";
  font-weight: 500;
}

.f22 {
  font-size: 1.1rem;
}
.f20 {
  font-size: 1rem;
}
.f16 {
  font-size: 0.8rem;
}
.f14 {
  font-size: 0.7rem;
}
.f10 {
  font-size: 0.5rem;
}

.text-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
}

.title {
  font-family: Playfair Display;
  font-size: 1rem;
  color: #16294c;
  margin-bottom: 0.5rem;
}

.circle-dot {
  height: 14px;
  width: 14px;
  border-radius: 50%;
  background-color: #eee;
}

.color-fiord {
  color: #435473;
}
.color-bunting {
  color: #16294c;
}
.color-nevada {
  color: #5d686e;
}
.bg-wedgewood {
  background-color: #5582a7;
}
.bg-burnt {
  background-color: #ed6b64;
}

@media screen and (max-width: 1200px) {
  .three-dots {
    max-width: calc(100vw - 670px);
  }
}

@media screen and (max-width: 768px) {
  .three-dots {
    max-width: calc(100vw - 230px);
  }
}
