.SecondFeedbackByTeacher-container {
  width: 100%;
  height: 97vh;
  background-color: #16294c;
  display: flex;
  justify-content: center;
  align-items: center;
}

.SecondFeedbackByTeacher-message-window {
  width: 472px;
  height: 524px;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding-top: 1.65rem;
  position: relative;
  left: 0;
  right: 0;
  top: 0;
}

.SecondFeedbackByTeacher-progressor p {
  font-family: Playfair Display;
  font-style: normal;
  font-weight: bold;
  font-size: 1.2rem;
  line-height: 140%;
  text-align: center;
  color: #16294c;
}

.SecondFeedbackByTeacher-progressor img {
  position: absolute;
  top: 1rem;
  right: 1.1rem;
}

.SecondFeedbackByTeacher-chat {
  display: flex;
  flex-direction: column;
  flex: 2;
  align-self: flex-end;
  margin-right: 1rem;
}

.SecondFeedbackByTeacher-teacher-message {
  font-family: Sofia Pro;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  color: #16294c;
  background-color: #e8f2fb;
  padding: 0.5rem 0.45rem;
  width: 360px;
  height: 100px;
  border-radius: 0.5rem 0.5rem 0.5rem 0;
  align-self: flex-end;
}

.SecondFeedbackByTeacher-chat-title {
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
}

.SecondFeedbackByTeacher-chat-title label {
  font-family: Sofia Pro;
  font-style: normal;
  font-weight: 600;
  font-size: 0.8rem;
  line-height: 140%;
  color: #16294c;
}

.SecondFeedbackByTeacher-chat-title input {
  background: #ffffff;
  border: 1px solid #f3f3f2;
  box-sizing: border-box;
  box-shadow: 0px 4px 24px -5px #e5e5e5;
  border-radius: 0.2rem;
  cursor: pointer;
  outline: none;
  padding: 0.45rem;
  margin-top: 0.25rem;
}

.SecondFeedbackByTeacher-chat-note {
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
}

.SecondFeedbackByTeacher-chat-note label {
  font-family: Sofia Pro;
  font-style: normal;
  font-weight: 600;
  font-size: 0.8rem;
  line-height: 140%;
  color: #16294c;
}

.SecondFeedbackByTeacher-chat-note textarea {
  background: #ffffff;
  border: 1px solid #f3f3f2;
  box-sizing: border-box;
  box-shadow: 0px 4px 24px -5px #e5e5e5;
  border-radius: 0.2rem;
  cursor: pointer;
  outline: none;
  padding: 0.45rem;
  margin-top: 0.25rem;
}

.SecondFeedbackByTeacher-chat-note textarea::placeholder {
  font-family: Poppins;
  font-style: normal;
  font-weight: 300;
  font-size: 0.8rem;
  line-height: 140%;
  color: #5d686e;
}

.SecondFeedbackByTeacher-chat-form button {
  font-family: Sofia Pro;
  font-style: normal;
  font-weight: normal;
  font-size: 0.95rem;
  line-height: 140%;
  color: #ffffff;
  background: #5582a7;
  border-radius: 0.2rem;
  cursor: pointer;
  border: none;
  outline: none;
  margin-top: 1.25rem;
  padding: 0.3rem;
  width: 100%;
}

.SecondFeedbackByTeacher-chat-first {
  font-family: Sofia Pro;
  font-size: 1rem;
  font-weight: 600;
  line-height: 140%;
  text-align: right;
  color: #16294c;
  background: #f3f3f2;
  border-radius: 0.5rem 0.5rem 0 0.5rem;
  width: 29%;
  align-self: flex-end;
  margin-top: 1.5rem;
  padding: 0.35rem 0.7rem;
}

.SecondFeedbackByTeacher-chat-second {
  font-family: Poppins;
  font-style: normal;
  font-weight: 300;
  font-size: 0.95rem;
  line-height: 140%;
  color: #16294c;
  background: #e8f2fb;
  border-radius: 0.5rem 0.5rem 0.5rem 0;
  width: 82%;
  align-self: flex-end;
  margin-top: 1.5rem;
  padding: 0.6rem 0.35rem;
}

.SecondFeedbackByTeacher-chat button {
  font-family: Sofia Pro;
  font-style: normal;
  font-weight: normal;
  font-size: 0.95rem;
  line-height: 140%;
  color: #ffffff;
  background: #5582a7;
  border-radius: 0.2rem;
  cursor: pointer;
  border: none;
  outline: none;
  margin-top: 4.5rem;
  padding: 0.3rem;
  width: 60%;
  align-self: center;
}
