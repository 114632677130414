.addLocation-container {
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  position: relative;
  right: 0;
  top: 0;
}

.addLocation-wrapper {
  width: 100%;
  height: 109%;
  display: flex;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  right: 0px;
  overflow-y: hidden;
}
.addLocation-form {
  padding: 1.8rem 1.4rem;
  width: 360px;
  height: 424px;
  background: #fafafc;
  box-shadow: 0px -4px 24px -5px rgba(22, 41, 76, 0.1);
  border-radius: 0.2rem 0.2rem 0 0;
  position: absolute;
  top: 2.6rem;
  opacity: 1;
  z-index: 100;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.addLocation-wrapper h1 {
  font-family: Playfair Display;
  font-style: normal;
  font-weight: bold;
  font-size: 1.2rem;
  line-height: 140%;
  color: #16294c;
  align-self: center;
}

.addLocation-wrapper p {
  font-family: Sofia Pro;
  font-style: normal;
  font-weight: 600;
  font-size: 0.8rem;
  line-height: 140%;
  color: #16294c;
  align-self: center;
}

.addLocation-form-crossBtn {
  position: absolute;
  top: 0.75rem;
  right: 0.75rem;
  height: 20px;
  width: 20px;
  cursor: pointer;
}

.addLocation-city,
.addLocation-admin {
  display: flex;
  flex-direction: column;
  margin-bottom: 0.5rem;
}
.addLocation-country-state {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.5rem;
}

.addLocation-country,
.addLocation-state {
  display: flex;
  flex-direction: column;
  flex: 0.49;
}
.addLocation-wrapper label {
  font-family: Sofia Pro;
  font-style: normal;
  font-weight: 600;
  font-size: 0.8rem;
  line-height: 140%;
  color: #16294c;
  padding-bottom: 0.25rem;
}

.addLocation-wrapper input {
  font-family: Poppins;
  font-style: normal;
  font-weight: 300;
  font-size: 0.75rem;
  line-height: 140%;
  color: #5d686e;
  background: #ffffff;
  border: 1px solid #f3f3f2;
  box-sizing: border-box;
  box-shadow: 0px 4px 24px -5px #e5e5e5;
  border-radius: 0.2rem;
  outline: none;
  padding: 0.4rem;
}

.addLocation-country-select {
  font-family: Poppins;
  font-style: normal;
  font-weight: 300;
  font-size: 0.75rem;
  line-height: 140%;
  color: #5d686e;
  background: #ffffff;
  border: 1px solid #f3f3f2;
  box-sizing: border-box;
  box-shadow: 0px 4px 24px -5px #e5e5e5;
  border-radius: 0.2rem;
  outline: none;
  padding: 0.5rem;
}

.addLocation-addbtn {
  font-family: Sofia Pro;
  font-size: 1rem;
  line-height: 140%;
  text-align: center;
  color: #ffffff;
  background: #5582a7;
  border-radius: 0.2rem;
  outline: none;
  border: none;
  cursor: pointer;
  padding: 0.2rem 0;
  margin-top: 0.2rem;
}

.addLocation-cancelbtn {
  font-family: Sofia Pro;
  font-size: 1rem;
  line-height: 140%;
  text-align: center;
  color: #5582a7;
  border-radius: 0.2rem;
  outline: none;
  border: none;
  cursor: pointer;
  padding: 0.2rem 0;
  background-color: transparent;
}
