.customModel {
  max-width: 776px;
  border-radius: 0.5rem;
  overflow: hidden;
  @media only screen and (max-width: 567px) {
    border-radius: 0;
  }
  .modal-content {
    border-radius: 0.4rem;
    border: 0 !important;
    overflow: hidden;
    background-color: transparent !important;
    @media only screen and (max-width: 567px) {
      border-radius: 0;
    }
    .modal-body {
      padding: 0 !important;

      .model-body-inner {
        background-color: #fff !important;
      }
      .modal-header {
        padding: 0 !important;
        border-radius: 0.4rem 0.4rem 0 0 !important;

        button {
          &.custom-close {
            float: none !important;
            position: absolute;
            width: 26px;
            height: 26px;
            border-radius: 50%;
            background-color: #ed6b64;
            opacity: 1;
            margin: 1.2rem;
            padding: 0 0;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            top: 0;
            span {
              display: inline-flex;
              width: 13px;
              height: 13px;
            }
          }
        }
      }
    }
  }
  &.retake-model {
    width: 516px;
    margin: 0 auto;
    @media only screen and (max-width: 567px) {
      width: 100%;
    }
    .modal-content {
      .modal-body {
        .modal-header {
          border: none;
        }
        .model-body-inner {
          background: none !important;
        }
      }
    }
  }
}
.modal-open {
  padding-right: 0 !important;
  height: 100vh;
  overflow-y: hidden!important;
}
