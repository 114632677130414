.search-box-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  background: #ffffff;
  box-shadow: 0px 4px 24px #e5e5e5;
  padding: 0.5rem 0.5rem;
  margin-top: 0.75rem;
  margin-bottom: 1.25rem;
  width: 100%;
  span{
    img{
      width: 26px;
      height: 26px;
    }
  }
  .search-box {
    -webkit-appearance: none;
    border: none;
    outline: none;
    padding: 0.3rem 0.5rem 0 0.5rem;
    font-family: Sofia Pro;
    font-style: normal;
    font-weight: 300;
    font-size: 1rem;
    line-height: 120%;
    color: #435473;
    background: transparent;
    width: 100%;
    box-sizing: border-box;
  }
}
