.card-container {
  display: flex;
  flex-direction: column;
  -moz-osx-font-smoothing: grayscale;
  backface-visibility: hidden;
  transform: translateZ(0);
  transition: transform 0.25s ease-out;
  background-color: #fff;
  box-shadow: 0px 4px 24px -5px #e5e5e5;
  cursor: pointer;
  /* &:hover {
    transform: scale(1.05);
  } */
  .thumb-container {
    width: 100%;
    height: 142px;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;

    .image {
      width: 100%;
      height: 95%;
      background-size: cover;
      background-position: center;
      margin-bottom: 0.25rem;
      display: flex;
      justify-content: center;
      align-items: center;
      .play-icon {
        background: #ed6b64;
        width: 34px;
        height: 34px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        box-sizing: border-box;
        box-shadow: 0px 4px 24px -5px #e5e5e5;
        img{
          width: 12px;
          height:15px;
        }
      }
    }
  }
  .thumb-description {
    padding: 0 0.5rem 0.5rem 0.5rem;
    height: 100px;
  }
  .article-description {
    padding: 0 0.5rem 0.5rem 0.5rem;
    height: 72px;
  }
  .plan-type {
    font-family: Sofia Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 0.7rem;
    line-height: 140%;
    text-align: center;
    letter-spacing: 0.04em;
    font-variant: small-caps;
    color: #435473;
  }
  h2 {
    font-family: Sofia Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 1rem;
    line-height: 140%;
    text-align: center;
    color: #16294c;
    &.recording-title {
      font-family: Sofia Pro;
      font-style: normal;
      font-weight: 300;
      font-size: 1rem;
      line-height: 140%;
      color: #152a4a;
      margin-bottom: 0;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      //height: 56px;
    }
    &.article-title {
      font-family: Sofia Pro;
      font-style: normal;
      font-weight: 300;
      font-size: 1rem;
      line-height: 140%;
      color: #152a4a;
      text-align: left;
      margin-bottom: 0;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      p {
        font-family: Sofia Pro;
        font-style: normal;
        font-weight: normal;
        font-size: 1rem;
        line-height: 140%;
        color: #152a4a;
        text-align: left;
        margin-bottom: 0;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        padding: 0 0;
        em {
          font-style: normal;
        }
      }
    }
  }
  .teacher-name {
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 0.7rem;
    line-height: 140%;
    text-align: center;
    color: #435473;
  }
  .article-author {
    font-family: Sofia Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 0.7rem;
    line-height: 140%;
    color: #5c686d;
    text-align: left;
    span {
      display: inline-flex;
      p {
        font-family: Sofia Pro;
        font-style: normal;
        font-weight: normal;
        font-size: 0.7rem;
        line-height: 140%;
        color: #5c686d;
        text-align: left;
      }
    }
  }
}
