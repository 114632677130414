.woe-index-container {
  padding: 1.1rem 1rem;
  box-sizing: border-box;
  width: 100%;
  position: relative;

  &.corporate-container {
    background: url("../../../Assets/wone_index_bg.svg") 100% 0px no-repeat;
    background-size: 130px;
  }

  .loader-container {
    width: 100%;
    text-align: center;
  }

  .something-went-wrong {
    width: 100%;
    text-align: center;

    h6 {
      text-align: center;
      font-size: 0.9rem;
    }
    p {
      text-align: center;
      font-size: 0.7rem;
    }
  }
}
.retake-anytime-container{
  text-align: center;
  p {
    font-family: "Sofia Pro";
    font-style: normal;
    font-weight: 400;
    font-size: 0.7rem;
    line-height: 140%;
    text-align: center;
    color: #16294c;
  }
  
  .btn-retake-assessment {
    font-family: "Sofia Pro";
    font-style: normal;
    font-weight: 400;
    font-size: 0.8rem;
    line-height: 140%;
    display: inline-block;
    text-align: center;
    color: #ffffff;
    background: #ed6b64;
    border-radius: 0.2rem;
    padding: 0.5rem 1.5rem;
    width: 160px;
    margin: 1rem auto;
  }
}

.retake-model {
  .retake-model-container {
    width: 517px;
    background: url("../../../Assets/retake_bg.svg") center -36px no-repeat #ffffff;
    background-size: 140px;
    margin: 0 auto;
    @media only screen and (max-width: 567px) {
      width: 100%;
    }
    .wone-modal-header {
      background: none;
      justify-content: flex-end;
    }

    .retake-model-body-container {
      background: none;
      h1 {
        font-family: "Playfair Display";
        font-style: normal;
        font-weight: 700;
        font-size: 1.6rem;
        line-height: 140%;
        color: #16294c;
        text-align: center;
        padding: 1.2rem 0.5rem 0.5rem 0.5rem;
      }

      p {
        font-family: "Sofia Pro";
        font-style: normal;
        font-weight: 400;
        font-size: 0.7rem;
        line-height: 140%;
        text-align: center;
        color: #16294c;
      }

      .btn-retake-assessment {
        font-family: "Sofia Pro";
        font-style: normal;
        font-weight: 400;
        font-size: 0.8rem;
        line-height: 140%;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        color: #ffffff;
        background: #ed6b64;
        border-radius: 0.2rem;
        padding: 0.5rem 1.5rem;
        width: 160px;
        margin: 1rem auto;
      }
    }
  }
}
