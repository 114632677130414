@import "custom";
@import "variables";

.PrimaryButton {
  width: 100%;
  height: 2.4rem;
  font-family: Sofia Pro;
  font-style: normal;
  font-weight: normal;
  font-size: 1rem;
  line-height: 140%;
  text-align: center;
  color: $white-color;
  background: $burnt-sienna-color;
  border-radius: 0.2rem;
  outline: none;
  border: none;
  cursor: pointer;
  padding: 0.4rem 0;
}

.SecondaryButton {
  @extend .PrimaryButton;
  background: $wedgewood-color;
}

.TextField {
  width: 100%;
  border: 1px solid $desert-storm-color;
  box-sizing: border-box;
  box-shadow: 0px 4px 24px -5px $box-shadow-color;
  border-radius: 0.2rem;
  padding: 0.6rem;
  color: $bunting-color;
  background: $white-color;
  font-family: "Poppins";
  font-weight: 300;
  font-size: 0.8rem;
  outline: 0;
  height: 2.2rem;
  resize: none;
}

.Message {
  font-family: Open Sans;
  font-weight: normal;
  font-size: 0.7rem;
  line-height: 140%;
  border-radius: 0.5rem;
  padding: 0.8rem 0.8rem;
  text-align: left;
}

.InfoMessage {
  @extend .Message;
  background-color: $polar-color;
}

.ErrorMessage {
  @extend .Message;
  color: $bunting-color;
  background-color: $albescent-color;
}

.form-check-input {
  &:focus {
    box-shadow: none;
    outline: none;
    border-width: 0;
  }
  &:checked[type="checkbox"] {
    /* Add if not using autoprefixer */
    -webkit-appearance: none;
    /* Remove most all native input styles */
    appearance: none;
    /* For iOS < 15 */
    background-color: $wedgewood-color;
    background-image: none;
    /* Not removed via appearance */
    font: inherit;
    color: currentColor;
    width: 0.92rem;
    height: 0.92rem;
    border-radius: 0.15em;
    transform: translateY(-0.075em);
    -webkit-transform: translateY(-0.075em);

    display: grid;
    place-content: center;
  }
  &[type="checkbox"] {
    cursor: pointer;
    border: 1px solid $nevada-color;
    border-radius: 0.2rem;
    background-color: $white-color;
    &:before {
      content: "";
      width: 0.52rem;
      height: 0.52rem;
      clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
      transform: scale(0);
      -webkit-transform: scale(0);
      transform-origin: bottom left;
      -webkit-transform-origin: bottom left;
      transition: 120ms transform ease-in-out;
      -webkit-transition: 120ms transform ease-in-out;
      box-shadow: inset 0.8rem 0.8rem $white-color;
      /* Windows High Contrast Mode */
      background-color: $wedgewood-color;
    }
    &:checked::before {
      transform: scale(1);
      -webkit-transform: scale(1);
    }
  }
}
.white-box {
  background: $white-color;
  box-shadow: 0px 4px 24px $box-shadow-color;
  padding: 1.2rem 1.2rem;
  box-sizing: border-box;
}
