.mobile-view-container {
  background-color: #16294c;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #fff;
}
.mobile-view-container h6 {
  font-family: Open Sans;
  font-size: 20px;
  margin: 0 0;
  padding: 0px 0 35px 0;
  text-align: center;
}
.mobile-view-container p {
  font-family: Open Sans;
  font-size: 14px;
  text-align: center;
  margin: 0 0;
  padding: 10px 0 0 0;
}
.mobile-view-header {
  padding: 0rem 2rem 0rem 2rem;
}
.mobile-view-body {
  padding: 0rem 4rem;
}
.mobile-view-logo-container {
  text-align: center;
}
.mobile-view-footer {
  padding: 35px 2rem 0rem 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.mobile-view-logo-icons {
  width: 100px;
  margin: 0px 0;
}
.app-store-icons {
  width: 150px;
  margin: 0px 0 20px 0;
  cursor: pointer;
}
