$bunting-color: #16294c;
$fiord-color: #435473;
$wedgewood-color: #5582a7;
$burnt-sienna-color: #ed6b64;
$mauvelous-color: #f29691;
$provincial-pink-color: #fdeeed;
$albescent-color: #f6e8d6;
$desert-storm-color: #f3f3f2;
$nevada-color: #5d686e;
$white-color: #ffffff;
$alabaster-color: #fbfbfb;
$polar-color: #e8f2fb;
$whisper-color: #fafafc;

$box-shadow-color: #e5e5e5;

$success-color: #198754;
