.corporate-container {
  margin-left: 0;
  padding: 0;
  width: 100%;
  height: auto;
  padding: 5.5rem 1rem 2rem;
  /* overflow: auto; */
  padding-left: 17.5rem;
  min-height: calc(100vh - 8px);
  transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
}
.aupolicy-link {
  display: flex;
  justify-content: center;
  position: sticky;
  padding: 0 0 1rem 17.5rem;
  width: 100%;
  cursor: pointer;
}
@media screen and (max-width: 768px) {
  .aupolicy-link {
    padding: 0 0 1rem 0rem;
  }
}
@media screen and (max-width: 1200px) {
  .corporate-container {
    padding-left: 13.5rem;
  }
}

@media screen and (max-width: 991px) {
  .corporate-container {
    flex-direction: column;
  }
}

@media screen and (max-width: 768px) {
  .corporate-container {
    padding: 0.75rem;
    padding-top: 4rem;
    margin-top: 0px;
  }
}
