.field {
  display: flex;
  align-items: center;
  width: 100%;
  height: 2.2rem;
  background-color: #ffffff;
  border: 1px solid #f3f3f2;
  box-sizing: border-box;
  box-shadow: 0px 4px 24px -5px #e5e5e5;
  border-radius: 0.2rem;
  margin-top: 1rem;
  box-sizing: border-box;

  img {
    background-color: #ffffff;
    margin: 0.5rem 0 0.5rem 0.6rem;
    width: 16px;
    height: 16px;
  }

  img:last-child {
    margin: 0.5rem 0.6rem 0.5rem 0;
  }

  input {
    flex: 3;
    outline: none;
    font-family: Poppins;
    font-weight: 300;
    font-size: 0.8rem;
    line-height: 140%;
    color: #16294c;
    border: none;
    padding: 0.5rem 0.6rem 0.5rem 0.6rem;
    width: 100%;
  }

  input::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #5d686e;
    opacity: 1; /* Firefox */
  }

  input:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #5d686e;
  }

  input::-ms-input-placeholder {
    /* Microsoft Edge */
    color: #5d686e;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px #ffffff inset !important;
  }
}

.form-check-label {
  font-family: Poppins;
  font-weight: 300;
  font-size: 0.8rem;
  color: #16294c;
}
