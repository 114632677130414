@import "../../global.scss";

.StepperProgress {
  height: 3.2px;
  width: 100%;
  max-width: 160px;
  margin-left: auto;
  margin-right: auto;
  background: #5481a633;
  border-radius: 1.5rem 0 0 1.5rem;

  .StepperProgress-done {
    height: 100%;
    background: #3d7db1;
    border-radius: inherit;
    transition: 1s ease !important;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 0;
    opacity: 0;
    transition: 1s ease 0.3s;
  }
}

.Onboarding-Ready-Container {
  background-image: url("../../Assets/background/sun-background.jpg") !important;
  background-position: top -80px center !important;
  background-repeat: repeat-x !important;
  background-size: 1152px 246px !important;

  @extend .Onboarding-Container;

  .Onboarding-Ready-Wrapper {
    @extend .Onboarding-Wrapper;

    padding-bottom: 0;
  }
}

.Onboarding-Container {
  position: absolute;
  width: 100%;
  background-image: url("../../Assets/background/top-right.png"),
    url("../../Assets/background/bottom-left.png"),
    url("../../Assets/background/bottom-right.png");
  background-position: top right, bottom left, bottom right;
  background-repeat: no-repeat;
  background-size: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;

  .Onboarding-Wrapper {
    min-height: 100%;
    /* Fallback for browsers do NOT support vh unit */
    min-height: 100vh;
    /* These two lines are counted as one :-)       */

    width: 100%;
    padding-top: 2.4rem;

    @media screen and (max-width: 992px) {
      padding-bottom: 7.5rem;
    }

    text-align: center;
    line-height: 140%;
    color: #16294c;

    h1 {
      font-family: Playfair Display;
      font-size: 1.1rem;
      text-align: center;
      margin-bottom: 1rem;
    }

    h2 {
      font-family: Playfair Display;
      font-weight: bold;
      font-size: 1.1rem;
    }

    p {
      font-family: Sofia Pro;
      font-size: 0.8rem;
      font-weight: 300;
      color: #152a4a;
      margin-bottom: 1.6rem;
    }

    a {
      color: #5582a7;
      font-weight: bold;
    }

    .GetStarted {
      .Logo {
        margin-bottom: 2.4rem;
        width: 100%;
        max-width: 240px;
        max-height: 80px;
        margin-left: auto;
        margin-right: auto;
        object-fit: contain;
      }

      ul {
        list-style-type: none;
        margin-bottom: 2rem;
        max-width: 694px;
        margin: auto;
        padding: 0;

        li {
          max-width: 283px;
          margin-left: auto;
          margin-right: auto;

          img {
            border: 0.1rem solid #fff;
            width: 100%;
            max-width: 160px;
            max-height: 160px;
            margin-left: auto;
            margin-right: auto;
            margin-bottom: 0.4rem;
            object-fit: cover;
            object-position: center;
          }

          h2 {
            width: 100%;
            max-width: 12.5rem;
            margin-left: auto;
            margin-right: auto;
            //padding: 0 0.8rem;
          }

          p {
            width: 100%;
            max-width: 17.25rem;
            margin-left: auto;
            margin-right: auto;
            font-family: Sofia Pro;
            font-weight: 300;
            font-size: 0.8rem;
            color: $bunting-color;
          }
        }
      }

      button {
        @extend .PrimaryButton;
        max-width: 320px;
        margin-bottom: 0.8rem;
      }

      p {
        font-family: Poppins;
        font-weight: 300;
        font-size: 0.8rem;
      }
    }

    .Welcome {
      margin-top: 2.4rem;
      width: 100%;
      max-width: 320px;
      margin-left: auto;
      margin-right: auto;

      .HeadImage {
        margin-bottom: 1.6rem;
        height: 118px;

        .WelcomeImage {
          position: absolute;
          height: 94px;
        }

        .PersonalCoachImage {
          width: 80px;
          height: 80px;
          border-radius: 50%;
          position: relative;
          top: 2.8rem;
        }
      }

      .InfoMessage {
        @extend .InfoMessage;
        border-radius: 0.5rem 0.5rem 0.5rem 0;
        margin-bottom: 1.28rem;
        color: $bunting-color;
      }

      button {
        @extend .SecondaryButton;
      }
    }

    .UserDetails {
      margin-top: 2.4rem;
      width: 100%;
      max-width: 320px;
      margin-left: auto;
      margin-right: auto;

      img {
        width: 100%;
        max-width: 123.46px;
        max-height: 155px;
        object-fit: contain;
      }

      h1 {
        margin-bottom: 1.6rem;
      }

      input {
        @extend .TextField;
        margin-bottom: 0.8rem;
      }

      button {
        @extend .SecondaryButton;
      }
    }

    .Pillar {
      margin-top: 2.4rem;
      width: 100%;
      max-width: 320px;
      margin-left: auto;
      margin-right: auto;

      img {
        margin-bottom: 1.6rem;
        width: 100%;
        max-width: 6.3rem;
        max-height: 6.3rem;
        object-fit: contain;
      }

      p {
        width: 100%;
        max-width: 266.4px;
        margin-left: auto;
        margin-right: auto;
        color: #152a4a;
      }

      ul {
        list-style-type: none;
        padding: 0;

        li {
          input[type="radio"] {
            display: none;
            appearance: none;
            -webkit-appearance: none;
            -moz-appearance: none;
            -ms-appearance: none;
            -o-appearance: none;
          }

          label:hover {
            cursor: pointer;
          }

          label {
            display: flex;
            justify-content: space-between;
            align-items: center;
            justify-items: center;
            border: 0.1rem solid #ffffff;
            box-sizing: border-box;
            height: 72px;

            div {
              padding: 1rem;

              h2 {
                font-family: Sofia Pro;
                font-weight: 500;
                font-size: 0.9rem;
                text-align: left;
                color: $bunting-color;
                margin: 0;
                line-height: 140%;
              }

              p {
                font-family: Open Sans;
                font-weight: 300;
                font-size: 0.7rem;
                text-align: left;
                color: #5c686d;
                margin: 0;
                line-height: 140%;
              }
            }

            img {
              margin: 0.5rem;
              width: 100%;
              max-width: 3.5rem;
              max-height: 3.5rem;
              object-fit: contain;
            }
          }

          background: #fbfbfb;
          box-shadow: 0px 4px 24px -5px #e5e5e5;
          margin-bottom: 0.6rem;
        }
      }
    }

    .Hours {
      margin-top: 2.4rem;
      width: 100%;
      max-width: 20rem;
      margin-left: auto;
      margin-right: auto;

      img {
        margin-bottom: 1.6rem;
        width: 100%;
        max-width: 106px;
        max-height: 95px;
        object-fit: contain;
      }

      h1 {
        max-width: 16.65rem;
        margin-left: auto;
        margin-right: auto;
      }

      p {
        max-width: 16.64rem;
        margin-left: auto;
        margin-right: auto;
        color: #152a4a;
      }

      select {
        width: 100%;
        border: none;
        border-radius: 0.2rem;
        padding: 0.6rem;
        color: #5d686e;
        font-family: "Poppins";
        font-weight: 300;
        font-size: 0.8rem;
        margin-bottom: 0.8rem;
        outline: 0;

        -webkit-appearance: none;
        /* Safari and Chrome */
        -moz-appearance: none;
        /* Firefox */
        -ms-appearance: none;
        -o-appearance: none;
        appearance: none;

        background-color: #ffffff;
        background-image: url("../../Assets/icon/navigation/arrow-drop-down.svg");
        background-repeat: no-repeat;
        background-position-x: calc(100% - 0.6rem);
        background-position-y: center;
        background-size: 0.6rem;
      }

      button[type="submit"] {
        @extend .SecondaryButton;
        margin-bottom: 0.8rem;
      }

      button[type="button"] {
        font-family: Sofia Pro;
        font-size: 1rem;
        border: none;
        color: #5582a7;
        padding: 0.4rem 0;
        background: inherit;
      }
    }

    .Injuries {
      @extend .Hours;

      textarea {
        @extend .TextField;
        margin-bottom: 0.8rem;
        height: 2.45rem;
      }
    }

    .calendar-invites {
      @extend .Hours;

      button[type="submit"] {
        @extend .SecondaryButton;
        box-shadow: 0px 4px 24px -5px #E5E5E5;
        margin-bottom: 0.8rem;
        background: #ffffff;
        color: #16294C;

        &:hover {
          border: 1px solid #5582A7;
        }
      }
    }

    .Preparing {
      @extend .Welcome;

      h1 {
        margin-bottom: 0.08rem;
      }

      p {
        margin-bottom: 0.8rem;
        font-weight: 500;
      }

      .svg {
        margin-top: 1.6rem;
        margin-bottom: 1.6rem;
        stroke-linecap: round;

        circle {
          stroke-width: 6.4px;
        }

        .svg-circle-text {
          font-family: Playfair Display;
          font-weight: bold;
          font-size: 1.8rem;
          fill: $bunting-color;
        }
      }

      .one {
        opacity: 0;
        animation: fadeIn 3s ease;
        animation-delay: 2.5s;
        animation-fill-mode: forwards;
      }

      .two {
        opacity: 0;
        animation: fadeIn 3s ease;
        animation-delay: 5s;
        animation-fill-mode: forwards;
      }

      .three {
        opacity: 0;
        animation: fadeIn 3s ease;
        animation-delay: 7.5s;
        animation-fill-mode: forwards;
      }

      .four {
        opacity: 0;
        animation: fadeInLast 3s ease;
        animation-delay: 10s;
        animation-fill-mode: forwards;
      }

      @keyframes fadeIn {
        0% {
          opacity: 0;
          color: #152a4a;
        }

        50% {
          opacity: 1;
          color: #152a4a;
        }

        100% {
          opacity: 1;
          color: #5c686d;
        }
      }

      @keyframes fadeInLast {
        0% {
          opacity: 0;
          color: #152a4a;
        }

        50% {
          opacity: 1;
          color: #152a4a;
        }

        100% {
          opacity: 1;
          color: #152a4a;
        }
      }
    }

    .Error {
      margin-top: 2.4rem;
      width: 100%;
      max-width: 20rem;
      margin-left: auto;
      margin-right: auto;

      button {
        @extend .PrimaryButton;
      }
    }

    .Ready {
      margin-top: 9.8rem;
      margin-bottom: 0.8rem;
      width: 100%;
      max-width: 21.45rem;
      margin-left: auto;
      margin-right: auto;

      h1,
      p {
        width: 100%;
        max-width: 15rem;
        margin-left: auto;
        margin-right: auto;
        color: $bunting-color;
        margin-bottom: 1rem;
      }

      .ListTitle {
        font-size: 0.7rem;
        font-weight: normal;
        margin-bottom: 1.6rem;
      }

      ul {
        list-style-type: none;
        max-width: 20rem;
        margin: auto;
        padding: 0;

        li {
          display: flex;
          align-items: center;
          background-color: #ffffff;
          margin-bottom: 0.8rem;
          padding: 0.6rem 0.8rem;
          text-align: left;
          line-height: 140%;
          color: $bunting-color;

          img {
            margin-right: 0.8rem;
          }

          h3 {
            font-family: Sofia Pro;
            font-weight: 500;
            font-size: 0.7rem;
            max-width: 13rem;
            margin-bottom: 0;
          }

          p {
            font-family: Open Sans;
            font-weight: 300;
            font-size: 0.7rem;
            max-width: 13rem;
            margin-bottom: 0;
          }
        }
      }

      button {
        @extend .PrimaryButton;
        max-width: 20rem;
      }
    }
  }
}

.Background {
  @extend .Onboarding-Container;

  .Page {
    @extend .Onboarding-Wrapper;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .container {
    flex-grow: 1;
    display: flex;
  }

  .SignUp,
  .SignIn,
  .Reset {
    width: 100%;
    max-width: 20rem;
    margin: auto;

    h1,
    p {
      max-width: 266px;
      margin-left: auto;
      margin-right: auto;
    }

    p {
      margin-bottom: 1.2rem;
    }

    button[type="submit"] {
      @extend .PrimaryButton;
      margin-top: 1.2rem !important;
      margin-bottom: 1.2rem !important;

      .spinner-border {
        vertical-align: inherit !important;
        border-width: 0.1rem;
      }
    }

    button.secondary-btn {
      @extend .SecondaryButton;
      margin-bottom: 1.2rem;
    }

    .ErrorMessage {
      @extend .ErrorMessage;
      max-width: 100%;
      text-align: center;
    }

    .CallToSignIn {
      font-family: Poppins;
      font-weight: 300;
      font-size: 0.8rem;
    }

    .back-button {
      background-color: #ED6B64;
      border-radius: 50%;
      width: 32px;
      height: 32px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;

      img {
        width: 80%;
      }
    }
  }

  .Footer {
    width: 100%;
    max-width: 14.6rem;
    margin: auto auto 1.6rem auto;
    font-size: 0.7rem !important;
    color: $nevada-color;

    a {
      text-decoration: underline;
      color: $wedgewood-color;
      font-weight: normal;
    }
  }
}