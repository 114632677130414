.wone-index-header {
  .pb-file-coantainer {
    width: 120px;
    height: 120px;
    margin: 0 auto;
    overflow: hidden;
    position: relative;
    text-align: center;

    @media screen and (max-width: 768px) {
      margin-top: 1rem;
    }

    .pb-container {
      object-fit: cover;
      border-radius: 50% !important;
      //cursor: pointer !important;
      position: relative;
      z-index: 6;

      .svg {
        stroke-linecap: round;

        circle {
          stroke-width: 6px;
        }
      }
    }

    .rounded-circle-width {
      width: 96px;
      height: 96px !important;
      object-fit: cover;
      border-radius: 50% !important;
      position: relative;
      margin-top: -10.85rem;
      z-index: 5;
    }

    input {
      position: relative;
      top: -9.7rem;
      margin: 0;
      z-index: 9;
      padding: 0;
      appearance: none;
      //display: none;
      opacity: 0;
      width: 120px;
      height: 120px;
      cursor: pointer;
      overflow: visible;
      align-items: baseline;
      text-overflow: ellipsis;
      white-space: pre;
      text-align: start !important;
    }

    &:hover {
      position: relative;
      background: url("../../../../Assets/upload_photo.svg") 0 0 no-repeat;
      background-size: 50% 50%;
      background-position: 50% 40%;
      background-color: rgba(255, 255, 255, 0.5);
      z-index: 30;
      width: 120px;
      height: 120px;
      border-radius: 50%;

      .pb-container {
        opacity: 0.2;
      }

      .rounded-circle-width {
        opacity: 0.2;
      }
    }
  }

  .white-box {
    background: #ffffff;
    border: 1px solid #e5e5e5;
    box-sizing: border-box;
    box-shadow: 0px 4px 24px #e5e5e5;
    border-radius: 0.2rem;
    margin-top: 2.25rem;
    @media screen and (max-width: 768px) {
      margin-top: 0;
    }

    p {
      font-family: Sofia Pro;
      font-style: normal;
      font-weight: normal;
      font-size: 0.6rem;
      line-height: 140%;
      text-align: center;
      color: #16294c;
    }
  }

  h5 {
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 1.4rem;
    line-height: 140%;
    color: #16294c;
    text-align: center;
    margin: 0;

    span {
      font-size: 0.6rem;
    }
  }

  h1 {
    font-family: Playfair Display;
    font-style: normal;
    font-weight: 600;
    font-size: 2.3rem;
    line-height: 124.5%;
    text-align: center;
    color: #16294c;
    margin-top: 0.5rem;
    sup {
      font-family: Playfair Display;
      font-style: normal;
      font-weight: 600;
      font-size: 1.2rem;
      top: 0;
      left: 0.25rem;
    }
  }

  h2 {
    font-family: Sofia Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 0.8rem;
    line-height: 140%;
    text-align: center;
    color: #5582a7;
  }
}
