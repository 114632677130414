body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* Zoom css override  */
  height: 100%;
  width: auto !important;
  min-width: auto !important;
  overflow: auto !important;
  font-size: 0.8rem !important;
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif !important;
  color: #212529 !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
/* body.ReactModal__Body--open{
  height: 100%;
  width: 100%;
  min-width: 1100px;
  overflow: hidden;
  font-size: 0.8rem;
  padding: 0;
  margin: 0;
  font-family: "Open Sans";
  color: #4a4a4a;    
} */

.Toastify.overlay:before {
  content: "";
  position: fixed;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.4);
  height: 100%;
  width: 100%;
  z-index: 11;
}
