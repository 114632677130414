@import "../../../variables.scss";
.terms-container {
  height: auto;
  margin-left: 0;
  min-height: calc(100vh - 298px);
  padding: 5.5rem 1rem 2rem 17.5rem;
  transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  width: 100%;
}
.aupolicy-container {
  .SectionPrivacyPolicy-container {
    width: 100%;
    height: 100%;
    background-color: var(--whisper-color);
    .SectionWeb-header h1 {
      font-family: "Playfair Display", sans-serif;
      font-style: normal;
      font-weight: bold;
      font-size: 27px;
      line-height: 140%;
      color: #16294c;
      padding-bottom: 5px;
    }
    .SectionWeb-header h2 {
      font-family: "Playfair Display", sans-serif;
      font-style: normal;
      font-weight: bold;
      font-size: 25px;
      line-height: 140%;
      color: #16294c;
      padding-bottom: 5px;
    }
    .SectionWeb-header h5 {
      font-family: "Playfair", sans-serif;
      font-style: normal;
      font-weight: bold;
      font-size: 17px;
      line-height: 140%;
      color: #16294c;
      padding-bottom: 5px;
    }
    .SectionWeb-header ol {
      list-style-type: none;
      list-style-position: inside;
    }
    .SectionWeb-header ul {
      // list-style-type: disc;
      list-style-type: none;
      list-style-position: inside;
      padding-left: 40px;
    }
    .SectionWeb-header ol li {
      font-family: "sofia_prolight", sans-serif;
      font-weight: normal;
      font-size: 17px;
      padding: 10px 0;
      margin-top: 5px;
      color: #16294c;
    }
  }
}
@media screen and (max-width: 800px) and (min-width: 320px) {
  .terms-container {
    padding: 60px 15px;
    width: 100%;
    height: 100%;
    .SectionWeb-header {
      padding-bottom: 20px;
    }
    .SectionWeb-header h1,
    h2,
    h5 {
      padding-bottom: 5px;
    }
    .SectionWeb-header ul,
    ol {
      padding-left: 10px;
    }
  }
}
